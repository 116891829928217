import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';

const PNLSetInfo = ({setDealInfo, setPandLs}) => {
    const SetRealCase = (e) => {
             
        let fake_deal = {
          "deal_info": {
            "name": "Money Maker",
            "address": "123 Sesame street",
            "gross_sqr_footage": 144000,
            "investment_type": "multi_family",
            "unit_amount": 40,
            "net_sqr_footage": 92000,
            "purchase_price": 8000000,
            "purchase_date": "2023-05-16T07:11:44.000Z"
          },
          "pandls": [
            {
              "transaction_name": "Transfer Taxes",
              "transaction_type": "closing_costs",
              "transactions": [
                {
                  "amount": "400000",
                  "date": "2019-05-01T07:14:21.000Z"
                }
              ]
            },
            {
              "transaction_name": "legal fee",
              "transaction_type": "closing_costs",
              "transactions": [
                {
                  "amount": "80000",
                  "date": "2019-05-01T07:14:43.000Z"
                }
              ]
            },
            {
              "transaction_name": "Due Diligence",
              "transaction_type": "closing_costs",
              "transactions": [
                {
                  "amount": "50000",
                  "date": "2019-05-01T07:15:15.000Z"
                }
              ]
            },
            {
              "transaction_name": "land Purchase",
              "transaction_type": "closing_costs",
              "transactions": [
                {
                  "amount": "8000000",
                  "date": "2019-05-01T07:31:02.000Z"
                }
              ]
            },
            {
              "transaction_name": "Architect",
              "transaction_type": "soft_costs",
              "transactions": [
                {
                  "amount": "48000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 48000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Engineering",
              "transaction_type": "soft_costs",
              "transactions": [
                {
                  "amount": "16000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 16000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "legal fee",
              "transaction_type": "soft_costs",
              "transactions": [
                {
                  "amount": "6000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Entitlement consultants",
              "transaction_type": "soft_costs",
              "transactions": [
                {
                  "amount": "6000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Entitlement Fees",
              "transaction_type": "soft_costs",
              "transactions": [
                {
                  "amount": "6000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 6000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Sitework",
              "transaction_type": "hard_costs",
              "transactions": [
                {
                  "amount": "200000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 200000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Foundation",
              "transaction_type": "hard_costs",
              "transactions": [
                {
                  "amount": "120000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Structure",
              "transaction_type": "hard_costs",
              "transactions": [
                {
                  "amount": "120000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 120000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "MEP",
              "transaction_type": "hard_costs",
              "transactions": [
                {
                  "amount": "240000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 240000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Exteriors",
              "transaction_type": "hard_costs",
              "transactions": [
                {
                  "amount": "320000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 320000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Interiors",
              "transaction_type": "hard_costs",
              "transactions": [
                {
                  "amount": "400000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 400000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Personnel",
              "transaction_type": "g_a",
              "transactions": [
                {
                  "amount": "20000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 20000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Office costs",
              "transaction_type": "g_a",
              "transactions": [
                {
                  "amount": "8000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Developer Fee",
              "transaction_type": "g_a",
              "transactions": [
                {
                  "amount": "40000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 40000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Sales center buildout",
              "transaction_type": "marketing",
              "transactions": [
                {
                  "amount": "8000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 8000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "General",
              "transaction_type": "marketing",
              "transactions": [
                {
                  "amount": "4000",
                  "date": "2019-06-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2019-07-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2019-08-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2019-09-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2019-10-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2019-11-01T07:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2019-12-01T07:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-01-01T07:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-02-01T07:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-03-01T07:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-04-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 4000,
                  "date": "2021-06-01T06:05:24.000Z"
                }
              ]
            },
            {
              "transaction_name": "Contingency",
              "transaction_type": "miscellaneous",
              "transactions": [
                {
                  "amount": "175000",
                  "date": "2020-05-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2020-06-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2020-07-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2020-08-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2020-09-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2020-10-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2020-11-01T07:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2020-12-01T07:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-01-01T07:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-02-01T07:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-03-01T07:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-04-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-05-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-06-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-07-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-08-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-09-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-10-01T06:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-11-01T07:05:24.000Z"
                },
                {
                  "amount": 175000,
                  "date": "2021-12-01T07:05:24.000Z"
                }
              ]
            }
          ]
        }

       let newParent = {};
       let parent = fake_deal.deal_info
       Object.entries(parent).map( ([k, v],index) => {
         if (k == 'purchase_date'){
           var today2 = new Date(v);
           newParent[k] = today2
         }else{
           newParent[k] = v
         }
       })

 

      let pnl = fake_deal.pandls
      let newpnl = pnl.map(profitlosses =>{

       let updatedtransactions = profitlosses.transactions.map(transaction =>{
         let newTransaction = {}
         Object.entries(transaction).map( ([k, v],index) => {
           if (k == 'date'){
             var today2 = new Date(v);
             newTransaction[k] = today2
           }else{
             newTransaction[k] = v
           }
         })
         return newTransaction
       })
       return {...profitlosses, 'transactions' : updatedtransactions};
     })


     setDealInfo(newParent)
     setPandLs(newpnl)
   }

            return ( 
        <div > 

    
<Button  variant="outline-primary" onClick={SetRealCase}> Set Sample Info</Button>
   
      </div>   
    )
}
 
export default PNLSetInfo;